import React, { useEffect, useState } from "react";
import AboutMe from "../components/AboutMe";
import ContactMe from "../components/ContactMe";
import Footer from "../components/Footer";
import HeroSection from "../components/HeroSection";
import MyWork from "../components/MyWork";
import Navbar from "../components/Navbar";
import Resume from "../components/Resume";
import { useSearchParams } from "react-router-dom";
import { InView } from "react-intersection-observer";
import Splash from "../components/Splash";

export default function Home() {
  const [activeSection, setActiveSection] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setTimeout(() => {
      if (searchParams.has("active")) {
        let id = null;
        if (searchParams.get("active") == "home") {
          id = "home-section";
        }

        if (searchParams.get("active") == "about-me") {
          id = "about-me-section";
        }

        if (searchParams.get("active") == "my-work") {
          id = "my-work-section";
        }

        if (searchParams.get("active") == "resume") {
          id = "resume-section";
        }

        if (searchParams.get("active") == "contact-me") {
          id = "contact-me-section";
        }

        if (id != null) {
          const element = document.getElementById(id);
          console.log("element", element, id);
          if (element) {
            window.scrollTo({
              top: element.offsetTop,
              behavior: "smooth",
            });
          }
        }
      }
      setActiveSection(searchParams.get("active"));
    }, 6000);
  }, [searchParams]);

  const waypointChangeHandler = (section) => {
    setActiveSection(section);
    console.log("waypointChangeHandler", section);
  };

  return (
    <Splash>
      <div>
        <Navbar activeSection={activeSection} />

        <InView
          threshold={0.5}
          as="div"
          onChange={(inView, entry) => {
            if (inView) {
              waypointChangeHandler("home");
            }
          }}
        >
          <HeroSection />
        </InView>

        <InView
          threshold={0.5}
          as="div"
          onChange={(inView, entry) => {
            if (inView) {
              waypointChangeHandler("about-me");
            }
          }}
        >
          <AboutMe />
        </InView>

        <InView
          threshold={0.5}
          as="div"
          onChange={(inView, entry) => {
            if (inView) {
              waypointChangeHandler("my-work");
            }
          }}
        >
          <MyWork />
        </InView>

        <InView
          threshold={0.5}
          as="div"
          onChange={(inView, entry) => {
            if (inView) {
              waypointChangeHandler("resume");
            }
          }}
        >
          <Resume />
        </InView>

        <InView
          threshold={0.5}
          as="div"
          onChange={(inView, entry) => {
            if (inView) {
              waypointChangeHandler("contact-me");
            }
          }}
        >
          <ContactMe />
        </InView>

        <Footer />
      </div>
    </Splash>
  );
}
