import React from "react";
import Navbar2 from "../components/Navbar2";
import Footer from "../components/Footer";
import { filterProjects } from "../helpers/Project";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import Splash from "../components/Splash";

export default function AllProjects() {
  const [selectedFilter, setselectedFilter] = useState(null);
  return (
    <>
      <Navbar2 />
      <div className="contact-me mt-5" style={{ backgroundColor: "#FFF" }}>
        <div className="container p-5 ">
          <div className="row">
            <div className="col-12 d-flex flex-column justify-content-center align-items-center pb-5">
              <h1>All Projects</h1>
              <div
                className="divider divider-blue-non-animation mt-2 mb-4"
                style={{ width: 210 }}
              ></div>
            </div>

            <div className="d-flex flex-row flex-wrap justify-content-center align-items-center mb-4">
              <button
                className={`btn text-uppercase mx-2 my-2 ${
                  selectedFilter == null ? "btn-primary" : "btn-outline-primary"
                }`}
                onClick={() => {
                  setselectedFilter(null);
                }}
              >
                All
              </button>
              <button
                className={`btn text-uppercase mx-2 my-2 ${
                  selectedFilter == "Website"
                    ? "btn-primary"
                    : "btn-outline-primary"
                }`}
                onClick={() => {
                  setselectedFilter("Website");
                }}
              >
                Websites
              </button>
              <button
                className={`btn text-uppercase mx-2 my-2 ${
                  selectedFilter == "WebApp"
                    ? "btn-primary"
                    : "btn-outline-primary"
                }`}
                onClick={() => {
                  setselectedFilter("WebApp");
                }}
              >
                Webapps
              </button>
              <button
                className={`btn text-uppercase mx-2 my-2 ${
                  selectedFilter == "MobileApp"
                    ? "btn-primary"
                    : "btn-outline-primary"
                }`}
                onClick={() => {
                  setselectedFilter("MobileApp");
                }}
              >
                Mobile App
              </button>
              {/* <button
                className={`btn text-uppercase mx-2 my-2 ${
                  selectedFilter == "DesktopApp"
                    ? "btn-primary"
                    : "btn-outline-primary"
                }`}
                onClick={() => {
                  setselectedFilter("DesktopApp");
                }}
              >
                Desktop App
              </button> */}
            </div>

            {filterProjects(selectedFilter).map((item) => {
              return (
                <div className="col-12 col-md-6 col-lg-3 my-3">
                  <div className="card baseBlock">
                    {item.images.length > 0 && item.images[0] != "" ? (
                      <img
                        src={item.images[0]}
                        className="card-img-top"
                        alt="..."
                        style={{
                          height: 180,
                          objectFit: "contain",
                          backgroundColor: "#ebebeb",
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          height: 180,
                          backgroundColor: "#ebebeb",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={"/Projects/no-image.png"}
                          className="card-img-top"
                          alt="..."
                          style={{
                            height: 130,
                            objectFit: "contain",
                            backgroundColor: "#ebebeb",
                          }}
                        />
                      </div>
                    )}

                    <div className="card-body">
                      <div className="info-section">
                        <h5 className="card-title project-title">
                          {item.title}
                        </h5>

                        <p className="card-text stack">
                          {item.stack_used.join(", ")}
                        </p>
                      </div>

                      <NavLink
                        to={"/project/" + item.id}
                        className="btn btn-primary btn-sm"
                      >
                        <i className="fa-solid fa-info-circle me-2"></i>More
                        Info
                      </NavLink>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
