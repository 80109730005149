import Projects from "../assets/Projects";

export const getLatestProjects = () => {
  return Projects.allprojects
    .filter((item) => item.priority == 1)
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .slice(0, 4);
};

export const filterProjects = (filter = null) => {
  if (filter == null) {
    return Projects.allprojects.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
  } else {
    return Projects.allprojects
      .filter((item) => item.project_type.includes(filter))
      .sort((a, b) => new Date(b.date) - new Date(a.date));
  }
};

export const singleProject = (id) => {
  return Projects.allprojects.find((item) => item.id == id);
};
