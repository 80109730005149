import React, { useEffect, useState } from "react";
import Loader from "../assets/loader.gif";
import { AnimatePresence, motion, useAnimate } from "framer-motion";

export default function Splash({ children }) {
  const [loaderScope, loaderAnimate] = useAnimate();
  const [homeScope, homeAnimate] = useAnimate();
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      loaderAnimate(
        loaderScope.current,
        {
          x: "-100%",
        },
        { ease: "easeOut", duration: 2 }
      );
    }, 4000);

    setTimeout(() => {
      loaderAnimate(loaderScope.current, {
        opacity: 0,
        display: "none",
        width: 0,
        height: 0,
      });
      setShowContent(true);
    }, 5500);

    setTimeout(() => {
      homeAnimate(
        homeScope.current,
        {
          //   x: 0,
          opacity: 1,
        },
        { ease: "easeIn", duration: 2.5 }
      );
    }, 6000);
  }, []);

  return (
    <>
      <AnimatePresence>
        {!showContent && (
          <motion.div
            ref={loaderScope}
            className="container vh-100 flex-row justify-content-center align-items-center"
            style={{ display: "flex" }}
            initial={{ x: "-100%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ ease: "easeIn", duration: 2 }}
          >
            <img src={Loader} width={250} height={250} />
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showContent && (
          <motion.div
            ref={homeScope}
            // exit={{
            //   x: 0,
            //   // opacity: 1
            // }}
            style={{
              //   transform: "translateX(-100%)",
              opacity: 0,
            }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
