import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Home from "./screens/Home";
import ProjectDetail from "./screens/ProjectDetail";
import AllProjects from "./screens/AllProjects";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/all-projects" element={<AllProjects />}></Route>
        <Route path="/project/:id" element={<ProjectDetail />}></Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
