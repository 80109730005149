import React from "react";
import "../styles/Resume.scss";
import ReactIcon from "../assets/react.png";
import VueIcon from "../assets/vue.svg";
import WordpressIcon from "../assets/wordpress.png";

import NodeIcon from "../assets/nodejs.svg";
import LaravelIcon from "../assets/laravel.svg";
import StrapiIcon from "../assets/strapi.svg";

import FlutterIcon from "../assets/flutter.svg";
import JavaIcon from "../assets/Java.png";

import MongoDBIcon from "../assets/mongodb.svg";
import MySqlIcon from "../assets/mysql.svg";

const Skill = ({ name, points }) => {
  return (
    <div className="row mt-3">
      <span className="skill-name col-12 col-md-4">{name}</span>
      <div className="col-12 col-md-8 d-flex flex-row align-items-center justify-content-start">
        {[...Array(points)].map((item) => {
          return <div className="filled skill-block"></div>;
        })}
        {[...Array(10 - points)].map((item) => {
          return <div className=" skill-block"></div>;
        })}
      </div>
    </div>
  );
};

export default function Resume() {
  const workExp = [
    {
      daterange: "2022-2024",
      company: "CodeRelix",
      jobtitle: "Fullstack developer",
      jobdescription: `For the past year, and continuing into the current year, I
                      have focused on developing React Native and Flutter apps,
                      as well as web applications using a range of popular
                      frameworks, including Laravel, React JS, Express JS, Vue
                      JS, and Strapi. Additionally, I have completed small
                      projects that involved working with Flask and C++.`,
    },

    {
      daterange: "2020-2021",
      company: "CodeRun/CodeMax",
      jobtitle: "Mobile App developer",
      jobdescription: `For two years, I focused on developing React Native,
                      Flutter, and native Android apps, as well as in-house web
                      applications, using popular frameworks like Laravel.`,
    },

    {
      daterange: "2019-2020",
      company: "Apexaura",
      jobtitle: "Fullstack developer",
      jobdescription: `For one year, I focused on creating React Native apps,
                      websites, and web applications using popular frameworks
                      such as Codeigniter, Laravel, and ReactJS.`,
    },

    {
      daterange: "2018-2019",
      company: "Haztech Innovation",
      jobtitle: "Internship",
      jobdescription: `For 3.5 months, I worked on creating React Native apps and
                      websites, utilizing a variety of programming languages,
                      including core PHP, HTML, CSS, and JavaScript.`,
    },

    {
      daterange: "2017",
      company: "Citrin Technologies India Pvt Ltd",
      jobtitle: "Internship",
      jobdescription: `Worked for 2 weeks as a WordPress developer. This was a
                      mandatory 2-week internship that was required by our
                      college.`,
    },
  ];

  return (
    <div
      className="resume"
      id="resume-section"
      style={{
        background: "#3586ff",
      }}
    >
      <div className="container p-5 ">
        <div className="row">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center">
            <h1 className="text-light">Skills</h1>
            <div
              className="divider divider-white-non-animation mt-2 mb-4"
              style={{ width: 100 }}
            ></div>
          </div>
          <div className="col-12 pb-5">
            <div className="row">
              <div className="col-12 col-md-6 my-4 ">
                <div className="card py-5 px-1 skill-card">
                  <div className="card-body">
                    <div className="d-flex flex-row justify-content-center align-items-center mb-4">
                      <img className="react-icon" src={ReactIcon} />
                      <img className="vue-icon" src={VueIcon} />
                      <img className="wordpress-icon" src={WordpressIcon} />
                    </div>
                    <h2 className="card-title mb-4">Frontend</h2>
                    <div>
                      <Skill name="React JS" points={8} />
                      <Skill name="Vue JS" points={5} />
                      <Skill name="Wordpress" points={4} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 my-4 ">
                <div className="card py-5 px-1 skill-card">
                  <div className="card-body">
                    <div className="d-flex flex-row justify-content-center align-items-center mb-4">
                      <img className="node-icon" src={NodeIcon} />
                      <img className="laravel-icon" src={LaravelIcon} />
                      <img className="strapi-icon" src={StrapiIcon} />
                    </div>
                    <h2 className="card-title mb-4">Backend</h2>
                    <div>
                      <Skill name="ExpressJS" points={8} />
                      <Skill name="Laravel" points={6} />
                      <Skill name="Strapi" points={6} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 my-4 ">
                <div className="card py-5 px-1 skill-card">
                  <div className="card-body">
                    <div className="d-flex flex-row justify-content-center align-items-center mb-4">
                      <img className="react-icon " src={ReactIcon} />
                      <img className="flutter-icon" src={FlutterIcon} />
                      <img className="java-icon" src={JavaIcon} />
                    </div>
                    <h2 className="card-title mb-4">Mobile</h2>
                    <div>
                      <Skill name="React Native" points={8} />
                      <Skill name="Flutter" points={4} />
                      <Skill name="Java" points={4} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 my-4 ">
                <div className="card py-5 px-1 skill-card">
                  <div className="card-body">
                    <div className="d-flex flex-row justify-content-center align-items-center mb-4">
                      <img className="mysql-icon" src={MySqlIcon} />
                      <img className="mongodb-icon" src={MongoDBIcon} />
                    </div>
                    <h2 className="card-title mb-4">Database</h2>
                    <div>
                      <Skill name="MySQL" points={8} />
                      <Skill name="MongoDB" points={5} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="bg-light mb-5 mt-4"
            style={{ height: 2, borderRadius: 2 }}
          />
          <div className="col-12 d-flex flex-column justify-content-center align-items-center p-4">
            <h1 className="text-light">Work Experience</h1>
            <div
              className="divider divider-white-non-animation mt-2 mb-4"
              style={{ width: 270 }}
            ></div>
          </div>

          {workExp.map((item, idx) => {
            return (
              <div className="col-12 col-md-6 " key={idx}>
                <div className="my-3 card work-card p-4 work-exp-block">
                  <div className="card-body d-flex flex-column justify-content-start align-items-center">
                    <span className="date-pill me-2">
                      <i class="fa-solid fa-calendar-days me-1"></i>
                      {item.daterange}
                    </span>
                    <span className="company-title mt-2">{item.company}</span>

                    <div className="mt-3">
                      <span className="d-flex flex-row justify-content-start align-items-center">
                        <div>
                          <div className="mb-1">
                            <i className="fa-solid fa-user-tie blue-icon me-2"></i>
                            <b className="text-dark job-title">Job Title</b>
                          </div>
                          <span className="text-dark text-center ">
                            {item.jobtitle}
                          </span>
                        </div>
                      </span>
                      <span className="mt-2 d-flex flex-column justify-content-start align-items-start">
                        <div className="d-flex flex-row justify-content-start align-items-center mt-2 mb-1">
                          <i className="fa-solid fa-file-lines blue-icon me-2"></i>
                          <span className="text-dark job-title">
                            <b>Job Description</b>
                          </span>
                        </div>
                        <span className="text-dark">{item.jobdescription}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
