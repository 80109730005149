import React, { useEffect, useState } from "react";
import "../styles/HeroSection.scss";
import Typewriter from "typewriter-effect/dist/core";

export default function HeroSection() {
  useEffect(() => {
    new Typewriter("#typewriter", {
      strings: ["fullstack applications", "android & ios apps"],
      autoStart: true,
      loop: true,
      pauseFor: 2000,
    });
  }, []);

  return (
    <div className="hero-section" id="home-section">
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>

      <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center vh-100">
            <div className="card hero-info-section">
              <div className="card-body info-card d-flex flex-column justify-content-center align-items-center">
                <span className="info-card-title text-center">
                  Ravino De Souza
                </span>
                <div className="divider divider-blue mt-2 mb-4"></div>
                <span className="text-dark d-flex flex-row info-card-subtitle mt-3">
                  <pre className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                    <span className="text-dark">
                      &lt;
                      <span className="text-custom-blue fw-bolder">code</span>
                      &gt;
                    </span>
                    <span className="text-dark">
                      I build <span className="ms-1" id="typewriter"></span>
                    </span>
                    <span className="text-dark">
                      &lt;/
                      <span className="text-custom-blue fw-bolder">code</span>
                      &gt;
                    </span>
                  </pre>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
