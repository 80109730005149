import React from "react";
import "../styles/MyWork.scss";
import { getLatestProjects } from "../helpers/Project";
import { NavLink } from "react-router-dom";

export default function MyWork() {
  return (
    <div className="contact-me " id="my-work-section">
      <div className="container p-5 ">
        <div className="row">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center pb-5">
            <h1>My Projects</h1>
            <div
              className="divider divider-blue-non-animation mt-2 mb-4"
              style={{ width: 210 }}
            ></div>
          </div>

          {getLatestProjects().map((item) => {
            return (
              <div className="col-12 col-md-6 col-lg-3 my-3">
                <div className="card baseBlock">
                  <img
                    src={item.images[0]}
                    className="card-img-top"
                    alt="..."
                    style={{
                      height: 180,
                      objectFit: "contain",
                      backgroundColor: "#ebebeb",
                    }}
                  />
                  <div className="card-body">
                    <div className="info-section">
                      <h5 className="card-title project-title">{item.title}</h5>

                      <p className="card-text stack">
                        {item.stack_used.join(", ")}
                      </p>
                    </div>

                    <NavLink
                      to={"/project/" + item.id}
                      className="btn btn-primary btn-sm"
                    >
                      <i className="fa-solid fa-info-circle me-2"></i>More Info
                    </NavLink>
                  </div>
                </div>
              </div>
            );
          })}

          <div className="col-12 my-4">
            <NavLink
              to={"/all-projects"}
              className="btn btn-outline-primary btn-md"
            >
              More projects
              <i className="fa-solid fa-angles-right ms-2 blink"></i>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
