const projects = {
  allprojects: [
    {
      id: 1,
      title: "Project, Task, Resource Management Tool",
      company: "Effective Architecture System (EAS)",
      images: [
        "/Projects/EAS/main.png",
        "/Projects/EAS/TaskResourceMgt/1.jpg",
        "/Projects/EAS/TaskResourceMgt/2.jpg",
        "/Projects/EAS/TaskResourceMgt/3.jpg",
        "/Projects/EAS/TaskResourceMgt/4.jpg",
        "/Projects/EAS/TaskResourceMgt/5.jpg",
        "/Projects/EAS/TaskResourceMgt/6.jpg",
        "/Projects/EAS/TaskResourceMgt/7.jpg",
        "/Projects/EAS/TaskResourceMgt/8.jpg",
        "/Projects/EAS/TaskResourceMgt/9.jpg",
        "/Projects/EAS/TaskResourceMgt/10.jpg",
        "/Projects/EAS/TaskResourceMgt/11.jpg",
        "/Projects/EAS/TaskResourceMgt/12.jpg",
        "/Projects/EAS/TaskResourceMgt/13.jpg",
        "/Projects/EAS/TaskResourceMgt/14.jpg",
        "/Projects/EAS/TaskResourceMgt/15.jpg",
        "/Projects/EAS/TaskResourceMgt/16.jpg",
        "/Projects/EAS/TaskResourceMgt/17.jpg",
        "/Projects/EAS/TaskResourceMgt/18.jpg",
        "/Projects/EAS/TaskResourceMgt/19.jpg",
        "/Projects/EAS/TaskResourceMgt/20.jpg",
        "/Projects/EAS/TaskResourceMgt/21.jpg",
        "/Projects/EAS/TaskResourceMgt/22.jpg",
        "/Projects/EAS/TaskResourceMgt/23.jpg",
        "/Projects/EAS/TaskResourceMgt/24.jpg",
        "/Projects/EAS/TaskResourceMgt/25.jpg",
        "/Projects/EAS/TaskResourceMgt/26.jpg",
      ],
      description:
        "We have developed a comprehensive web application that enables efficient project, task, and resource management. To enhance data visualization, we have integrated a range of chart libraries such as bar and pie charts into our dashboard. Additionally, we have utilized Gantt charts to display project and resource data, enabling users to easily monitor and manage tasks. To simplify task allocation and scheduling, we have incorporated the Fullcalendar library, which allows users to drag-and-drop tasks to allocate them to resources. Our web application offers an intuitive and user-friendly interface that streamlines project management and boosts productivity.",
      stack_used: ["Codeigniter V3", "MySQL"],
      priority: 0,
      project_type: ["WebApp"],
      links: [],
      project_for: "work",
      date: "2019-04-12",
    },
    {
      id: 2,
      title: "Intelligent Bot (Final Year Project)",
      company: "",
      images: [
        "/Projects/IntelligentBot/main.jpg",
        "/Projects/IntelligentBot/1.png",
        "/Projects/IntelligentBot/2.png",
        "/Projects/IntelligentBot/3.png",
        "/Projects/IntelligentBot/4.png",
        "/Projects/IntelligentBot/5.png",
        "/Projects/IntelligentBot/6.png",
        "/Projects/IntelligentBot/7.png",
        "/Projects/IntelligentBot/8.png",
        "/Projects/IntelligentBot/9.png",
        "/Projects/IntelligentBot/10.png",
        "/Projects/IntelligentBot/11.png",
        "/Projects/IntelligentBot/12.png",
        "/Projects/IntelligentBot/13.png",
        "/Projects/IntelligentBot/14.png",
        "/Projects/IntelligentBot/15.png",
        "/Projects/IntelligentBot/16.png",
      ],
      description: `Our expert system is designed to provide answers to a wide range of questions, from music to movies and beyond. With its user-friendly interface, users can easily access and utilize the system's advanced features, including the Admin Panel. This panel enables users to create their own customized bots using either pre-existing databases or question-answer formats.
            To ensure seamless functionality,
            we developed the backend of the system using Python and integrated it with the user interface using HTML,
            CSS,
            and JQUERY.In addition,
            we leveraged the powerful Flask framework to connect the backend to the user interface,
            resulting in a highly - responsive and efficient system.Our expert system offers a versatile and flexible solution
            for users seeking expert advice on a variety of topics.
            `,
      stack_used: ["Python", "HTML", "CSS", "Jquery", "Flask", "Sqlite3"],
      priority: 0,
      project_type: ["WebApp"],
      links: [],
      project_for: "personal",
      date: "2018-05-01",
    },
    // {
    //   id: 3,
    //   title: "Saksham Goa",
    //   company: "sakshamgoa",
    //   images: [],
    //   description: "",
    //   stack_used: ["HTML", "Php", "Jquery", "Mysql"],
    //   priority: 0,
    //   project_type: ["Website"],
    //   links: [
    //     {
    //       name: "sakshamgoa.org",
    //       link: "https://sakshamgoa.org/",
    //     },
    //   ],
    //   project_for: "work",
    //   date: "2019-01-01",
    // },
    {
      id: 4,
      title: "GVM College",
      company: "",
      images: [
        "/Projects/GVMCollege/main.png",
        "/Projects/GVMCollege/1.png",
        "/Projects/GVMCollege/2.png",
        "/Projects/GVMCollege/3.png",
        "/Projects/GVMCollege/4.png",
        "/Projects/GVMCollege/5.png",
        "/Projects/GVMCollege/6.png",
        "/Projects/GVMCollege/7.png",
        "/Projects/GVMCollege/8.png",
        "/Projects/GVMCollege/9.png",
        "/Projects/GVMCollege/10.png",
        "/Projects/GVMCollege/11.png",
        "/Projects/GVMCollege/12.png",
        "/Projects/GVMCollege/13.png",
        "/Projects/GVMCollege/14.png",
        "/Projects/GVMCollege/15.png",
        "/Projects/GVMCollege/16.png",
        "/Projects/GVMCollege/17.png",
        "/Projects/GVMCollege/18.png",
        "/Projects/GVMCollege/19.png",
        "/Projects/GVMCollege/20.png",
        "/Projects/GVMCollege/21.png",
        "/Projects/GVMCollege/22.png",
        "/Projects/GVMCollege/23.png",
        "/Projects/GVMCollege/24.png",
        "/Projects/GVMCollege/25.png",
        "/Projects/GVMCollege/26.png",
        "/Projects/GVMCollege/27.png",
        "/Projects/GVMCollege/28.png",
        "/Projects/GVMCollege/29.png",
        "/Projects/GVMCollege/30.png",
        "/Projects/GVMCollege/31.png",
        "/Projects/GVMCollege/32.png",
        "/Projects/GVMCollege/33.png",
        "/Projects/GVMCollege/34.png",
        "/Projects/GVMCollege/35.png",
        "/Projects/GVMCollege/36.png",
        "/Projects/GVMCollege/37.png",
        "/Projects/GVMCollege/38.png",
        "/Projects/GVMCollege/39.png",
        "/Projects/GVMCollege/40.png",
        "/Projects/GVMCollege/41.png",
        "/Projects/GVMCollege/42.png",
        "/Projects/GVMCollege/43.png",
        "/Projects/GVMCollege/44.png",
        "/Projects/GVMCollege/45.png",
        "/Projects/GVMCollege/46.png",
        "/Projects/GVMCollege/47.png",
        "/Projects/GVMCollege/48.png",
        "/Projects/GVMCollege/49.png",
        "/Projects/GVMCollege/50.png",
        "/Projects/GVMCollege/51.png",
        "/Projects/GVMCollege/52.png",
        "/Projects/GVMCollege/53.png",
        "/Projects/GVMCollege/54.png",
        "/Projects/GVMCollege/55.png",
        "/Projects/GVMCollege/56.png",
        "/Projects/GVMCollege/57.png",
        "/Projects/GVMCollege/58.png",
        "/Projects/GVMCollege/59.png",
      ],
      description: "",
      stack_used: ["HTML", "Php", "Jquery", "Mysql"],
      priority: 0,
      project_type: ["Website"],
      links: [
        {
          name: "gvmcollege.com",
          link: "https://gvmcollege.com/",
        },
      ],
      project_for: "work",
      date: "2019-02-01",
    },
    // {
    //   id: 5,
    //   title: "Company 1317",
    //   company: "company1317",
    //   images: [
    //     "/Projects/Company1317/main.png",
    //     "/Projects/Company1317/1.png",
    //     "/Projects/Company1317/2.png",
    //     "/Projects/Company1317/3.png",
    //     "/Projects/Company1317/4.png",
    //     "/Projects/Company1317/5.png",
    //     "/Projects/Company1317/6.png",
    //   ],
    //   description: "",
    //   stack_used: ["HTML", "Php", "Jquery", "Mysql"],
    //   priority: 0,
    //   project_type: ["Website"],
    //   links: [
    //     {
    //       name: "company1317.com",
    //       link: "https://company1317.com/",
    //     },
    //   ],
    //   project_for: "work",
    //   date: "2019-03-01",
    // },
    // {
    //   id: 6,
    //   title: "St. Francis Chapel",
    //   company: "",
    //   images: [
    //     "/Projects/STFX/main.jpg",
    //     "/Projects/STFX/1.png",
    //     "/Projects/STFX/2.png",
    //     "/Projects/STFX/3.png",
    //   ],
    //   description: "",
    //   stack_used: ["wordpress"],
    //   priority: 0,
    //   project_type: ["Website"],
    //   links: [
    //     {
    //       name: "stfrancischapelguirdolim.in",
    //       link: "http://stfrancischapelguirdolim.in/",
    //     },
    //   ],
    //   project_for: "personal",
    //   date: "2020-10-01",
    // },
    {
      id: 7,
      title: "Psytech",
      company: "",
      images: [
        "/Projects/Psytech/main.jpg",
        "/Projects/Psytech/1.png",
        "/Projects/Psytech/2.png",
        "/Projects/Psytech/3.png",
        "/Projects/Psytech/4.png",
        "/Projects/Psytech/5.png",
        "/Projects/Psytech/6.png",
        "/Projects/Psytech/7.png",
        "/Projects/Psytech/8.png",
        "/Projects/Psytech/9.png",
        "/Projects/Psytech/10.png",
        "/Projects/Psytech/11.png",
        "/Projects/Psytech/12.png",
        "/Projects/Psytech/13.png",
      ],
      description: "",
      stack_used: ["HTML", "Php", "Jquery", "Mysql"],
      priority: 0,
      project_type: ["Website"],
      links: [
        {
          name: "psytech.in",
          link: "https://www.psytech.in/",
        },
      ],
      project_for: "work",
      date: "2018-12-04",
    },
    {
      id: 8,
      title: "EAS",
      company: "Effective Architecture System (EAS)",
      images: [
        "/Projects/EAS/main.png",
        "/Projects/EAS/Website/1.png",
        "/Projects/EAS/Website/2.png",
        "/Projects/EAS/Website/3.png",
        "/Projects/EAS/Website/4.png",
        "/Projects/EAS/Website/5.png",
        "/Projects/EAS/Website/6.png",
        "/Projects/EAS/Website/7.png",
        "/Projects/EAS/Website/8.png",
        "/Projects/EAS/Website/9.png",
        "/Projects/EAS/Website/10.png",
        "/Projects/EAS/Website/11.png",
      ],
      description: "",
      stack_used: ["HTML", "Php", "Jquery", "Mysql"],
      priority: 0,
      project_type: ["Website"],
      links: [
        {
          name: "eas.org.in",
          link: "http://eas.org.in/",
        },
      ],
      project_for: "work",
      date: "2019-04-01",
    },
    {
      id: 9,
      title: "Architect RajeevSukh",
      company: "",
      images: [
        "/Projects/ArchRajeevSukh/main.jpg",
        "/Projects/ArchRajeevSukh/1.png",
        "/Projects/ArchRajeevSukh/2.png",
        "/Projects/ArchRajeevSukh/3.png",
        "/Projects/ArchRajeevSukh/4.png",
        "/Projects/ArchRajeevSukh/5.png",
        "/Projects/ArchRajeevSukh/6.png",
        "/Projects/ArchRajeevSukh/7.png",
        "/Projects/ArchRajeevSukh/8.png",
        "/Projects/ArchRajeevSukh/9.png",
        "/Projects/ArchRajeevSukh/10.png",
        "/Projects/ArchRajeevSukh/11.png",
        "/Projects/ArchRajeevSukh/12.png",
        "/Projects/ArchRajeevSukh/13.png",
        "/Projects/ArchRajeevSukh/14.png",
      ],
      description: "",
      stack_used: ["HTML", "Php", "Jquery", "Mysql"],
      priority: 0,
      project_type: ["Website"],
      links: [
        {
          name: "archrajeevsukh.in",
          link: "https://archrajeevsukh.in/",
        },
      ],
      project_for: "work",
      date: "2019-08-01",
    },
    // {
    //   id: 10,
    //   title: "Hrudayanand",
    //   company: "",
    //   images: [],
    //   description: "",
    //   stack_used: ["HTML", "Php", "Jquery", "Mysql"],
    //   priority: 0,
    //   project_type: ["Website"],
    //   links: [
    //     {
    //       name: "hrudayanand",
    //       link: "https://testapex.website/hrudayanand",
    //     },
    //   ],
    //   project_for: "work",
    //   date: "2019-09-01",
    // },
    {
      id: 11,
      title: "Ascendfins",
      company: "ascendfins.in",
      images: [
        "/Projects/Ascendfins/main.jpg",
        "/Projects/Ascendfins/1.png",
        "/Projects/Ascendfins/2.png",
        "/Projects/Ascendfins/3.png",
        "/Projects/Ascendfins/4.png",
        "/Projects/Ascendfins/5.png",
        "/Projects/Ascendfins/6.png",
        "/Projects/Ascendfins/7.png",
        "/Projects/Ascendfins/8.png",
        "/Projects/Ascendfins/9.png",
      ],
      description: "",
      stack_used: ["Laravel"],
      priority: 0,
      project_type: ["Website"],
      links: [
        {
          name: "ascendfins.in",
          link: "https://ascendfins.in/",
        },
      ],
      project_for: "work",
      date: "2020-06-01",
    },
    {
      id: 12,
      title: "The Yellowskies",
      company: "theyellowskies",
      images: [
        "/Projects/Yellowskies/main.png",
        "/Projects/Yellowskies/1.png",
        "/Projects/Yellowskies/2.png",
        "/Projects/Yellowskies/3.png",
        "/Projects/Yellowskies/4.png",
        "/Projects/Yellowskies/5.png",
        "/Projects/Yellowskies/6.png",
        "/Projects/Yellowskies/7.png",
        "/Projects/Yellowskies/8.png",
        "/Projects/Yellowskies/9.png",
        "/Projects/Yellowskies/10.png",
        "/Projects/Yellowskies/11.png",
        "/Projects/Yellowskies/12.png",
        "/Projects/Yellowskies/13.png",
        "/Projects/Yellowskies/14.png",
        "/Projects/Yellowskies/15.png",
        "/Projects/Yellowskies/16.png",
      ],
      description: "",
      stack_used: ["React JS", "Express JS"],
      priority: 0,
      project_type: ["WebApp"],
      links: [
        {
          name: "theyellowskies.com",
          link: "http://theyellowskies.com/",
        },
      ],
      project_for: "work",
      date: "2021-03-01",
    },
    {
      id: 13,
      title: "Davion Healthcare",
      company: "Davion Healthcare Plc",
      images: [
        "/Projects/Davion/main.png",
        "/Projects/Davion/1.png",
        "/Projects/Davion/2.png",
        "/Projects/Davion/3.png",
        "/Projects/Davion/4.png",
        "/Projects/Davion/5.png",
      ],
      description: "",
      stack_used: ["React JS", "Strapi"],
      priority: 0,
      project_type: ["Website"],
      links: [
        {
          name: "davionhealthcare.com",
          link: "https://www.davionhealthcare.com/",
        },
      ],
      project_for: "work",
      date: "2022-07-01",
    },
    {
      id: 14,
      title: "Student Management System",
      company: "",
      images: [
        "/Projects/StudentMgt/main.jpeg",
        "/Projects/StudentMgt/1.PNG",
        "/Projects/StudentMgt/2.PNG",
        "/Projects/StudentMgt/3.PNG",
        "/Projects/StudentMgt/4.PNG",
        "/Projects/StudentMgt/5.PNG",
        "/Projects/StudentMgt/6.PNG",
        "/Projects/StudentMgt/7.PNG",
        "/Projects/StudentMgt/8.PNG",
        "/Projects/StudentMgt/9.1.PNG",
        "/Projects/StudentMgt/9.2.PNG",
        "/Projects/StudentMgt/10.PNG",
        "/Projects/StudentMgt/11.PNG",
        "/Projects/StudentMgt/12.1.PNG",
        "/Projects/StudentMgt/12.2.PNG",
        "/Projects/StudentMgt/12.3.PNG",
        "/Projects/StudentMgt/12.4.PNG",
        "/Projects/StudentMgt/13.PNG",
        "/Projects/StudentMgt/14.PNG",
        "/Projects/StudentMgt/15.1.PNG",
        "/Projects/StudentMgt/15.2.PNG",
        "/Projects/StudentMgt/15.3.PNG",
        "/Projects/StudentMgt/15.4.PNG",
        "/Projects/StudentMgt/16.PNG",
        "/Projects/StudentMgt/17.PNG",
        "/Projects/StudentMgt/18.PNG",
        "/Projects/StudentMgt/19.PNG",
        "/Projects/StudentMgt/20.PNG",
        "/Projects/StudentMgt/21.PNG",
        "/Projects/StudentMgt/22.PNG",
        "/Projects/StudentMgt/23.PNG",
        "/Projects/StudentMgt/24.PNG",
        "/Projects/StudentMgt/25.PNG",
        "/Projects/StudentMgt/26.PNG",
        "/Projects/StudentMgt/27.PNG",
        "/Projects/StudentMgt/28.1.PNG",
        "/Projects/StudentMgt/28.2.PNG",
        "/Projects/StudentMgt/28.3.PNG",
        "/Projects/StudentMgt/28.4.PNG",
        "/Projects/StudentMgt/28.5.PNG",
        "/Projects/StudentMgt/28.6.PNG",
        "/Projects/StudentMgt/28.7.PNG",
        "/Projects/StudentMgt/28.8.PNG",
        "/Projects/StudentMgt/28.9.PNG",
        "/Projects/StudentMgt/28.10.PNG",
        "/Projects/StudentMgt/28.11.PNG",
        "/Projects/StudentMgt/28.12.PNG",
        "/Projects/StudentMgt/29.PNG",
        "/Projects/StudentMgt/30.PNG",
        "/Projects/StudentMgt/31.png",
        "/Projects/StudentMgt/32.png",
        "/Projects/StudentMgt/33.png",
        "/Projects/StudentMgt/34.png",
        "/Projects/StudentMgt/35.png",
      ],
      description: `Our web application provides an intuitive platform for Training Placement Officers to efficiently manage company details, requirement drives, student records, and track placements. With its user-friendly interface, this web application allows users to easily access and organize relevant information.
            The Training Placement Officer can quickly view and manage all aspects of the recruitment process,
            including company information and requirements,
            student details,
            and placement records.This information is easily accessible and can be updated in real - time,
            providing a streamlined and efficient workflow
            for managing multiple recruitment drives.
            Our web application offers a comprehensive solution
            for managing student placements and helps ensure that each student has the best possible chance of securing their desired job.With its powerful functionality and ease - of -use,
            our web application is a valuable tool
            for Training Placement Officers seeking to optimize their recruitment processes.
            `,
      stack_used: ["Html", "Php", "Jquery", "Mysql", "React Native"],
      priority: 0,
      project_type: ["Website", "MobileApp"],
      links: [],
      project_for: "personal",
      date: "2019-04-01",
    },
    // {
    //   id: 15,
    //   title: "Watch Store (College Mini project)",
    //   company: "",
    //   images: [],
    //   description: `Our offline Watch store offers customers the convenience of purchasing wristwatches in-person, similar to the experience of shopping on an online store. The user interface of our store was developed using HTML and CSS, resulting in a visually appealing and intuitive interface that enhances the overall shopping experience.
    //         To streamline the shopping process,
    //         we have integrated a SQL database to store product information,
    //         allowing customers to easily browse and select their preferred products.This database also enables us to efficiently manage inventory levels and ensure that all product information is up - to - date and accurate.
    //         Our Watch store provides customers with a convenient and personalized shopping experience,
    //         with the added benefit of in -person assistance and support from our knowledgeable staff.Whether a customer is looking
    //         for a specific type of wristwatch or simply browsing
    //         for a new accessory,
    //         our store offers a comprehensive selection of high - quality products and exceptional customer service.
    //         `,
    //   stack_used: ["HTML", "CSS", "PHP", "MySQL"],
    //   priority: 0,
    //   project_type: ["Website"],
    //   links: [],
    //   project_for: "personal",
    //   date: "2016-05-01",
    // },
    // {
    //   id: 16,
    //   title: "Interview Web App",
    //   company: "CodeMax",
    //   images: [],
    //   description:
    //     "The Laravel project we developed utilizes a robust MySQL database backend. Our company leverages this platform to test and evaluate potential candidates for available job positions. The tests are specifically designed to assess the skills and knowledge of the job seekers across various fields. These assessments are multiple-choice question (MCQ) based and are customized to the specific area of expertise of the candidates. This approach has proven to be an effective tool for our company to select the most suitable and qualified candidates for our job openings.",
    //   stack_used: ["Laravel"],
    //   priority: 0,
    //   project_type: ["WebApp"],
    //   links: [],
    //   project_for: "work",
    //   date: "2021-04-01",
    // },
    {
      id: 17,
      title: "Hubstaff Web App",
      company: "CodeMax",
      images: ["/Projects/Hubstaff/main.png"],
      description:
        "Our Laravel project is built on a powerful MySQL database and serves as an internal tool to generate comprehensive reports based on data gathered from Hubstaff. The reports are automatically sent for approval to all relevant parties including managers, CTO, and other stakeholders before being finalized and shared with the client. This streamlined approach ensures that all necessary approvals are obtained and that the reports are accurate and reliable. By leveraging this solution, our company has been able to efficiently and effectively manage the reporting process, saving valuable time and resources.",
      stack_used: ["Laravel"],
      priority: 0,
      project_type: ["WebApp"],
      links: [],
      project_for: "work",
      date: "2021-08-01",
    },
    {
      id: 18,
      title: "Toddlers & Tots",
      company: "Toddlers & Tots",
      images: [
        "/Projects/ToddlerAndTots/main.png",
        "/Projects/ToddlerAndTots/1.png",
        "/Projects/ToddlerAndTots/2.png",
        "/Projects/ToddlerAndTots/3.png",
        "/Projects/ToddlerAndTots/4.png",
        "/Projects/ToddlerAndTots/5.png",
        "/Projects/ToddlerAndTots/6.png",
        "/Projects/ToddlerAndTots/7.png",
        "/Projects/ToddlerAndTots/8.png",
        "/Projects/ToddlerAndTots/9.png",
        "/Projects/ToddlerAndTots/10.png",
        "/Projects/ToddlerAndTots/11.png",
        "/Projects/ToddlerAndTots/12.png",
        "/Projects/ToddlerAndTots/13.png",
        "/Projects/ToddlerAndTots/14.png",
        "/Projects/ToddlerAndTots/15.png",
        "/Projects/ToddlerAndTots/16.png",
        "/Projects/ToddlerAndTots/17.png",
        "/Projects/ToddlerAndTots/18.png",
      ],
      description: `Our E-commerce project offers a comprehensive solution for products related to kids, with a user-friendly client site that enables customers to easily browse and purchase items. The project also includes a dashboard that provides access to multiple levels of administration, including the main admin, sub-admins, and vendors.
            The dashboard allows administrators to efficiently manage products,
            users,
            orders,
            and other key aspects of the E - commerce platform.With its intuitive and customizable interface,
            the dashboard provides a streamlined workflow
            for managing multiple aspects of the business.
            The client site offers a user - friendly and responsive interface that enhances the overall shopping experience
            for customers.With a wide range of products related to kids,
            customers can easily browse and select their preferred items,
            with secure payment options and efficient order processing.
            Overall,
            our E - commerce project is a robust and scalable platform that offers a comprehensive solution
            for businesses focused on products related to kids.With its flexible administration dashboard and user - friendly client site,
            our project provides an intuitive and streamlined experience
            for customers and administrators alike.
            `,
      stack_used: ["React JS", "Strapi"],
      priority: 1,
      project_type: ["WebApp"],
      links: [
        {
          name: "toddlersandtots.com",
          link: "https://toddlersandtots.com/",
        },
      ],
      project_for: "work",
      date: "2022-09-01",
    },
    {
      id: 19,
      title: "Gatepass-Register Mgt",
      company: "Essity",
      images: [
        "/Projects/EssityGatepass/main.jpg",
        "/Projects/EssityGatepass/1.png",
        "/Projects/EssityGatepass/2.png",
        "/Projects/EssityGatepass/3.png",
        "/Projects/EssityGatepass/4.png",
        "/Projects/EssityGatepass/5.png",
        "/Projects/EssityGatepass/6.png",
        "/Projects/EssityGatepass/7.png",
        "/Projects/EssityGatepass/8.png",
        "/Projects/EssityGatepass/9.png",
        "/Projects/EssityGatepass/10.png",
        "/Projects/EssityGatepass/11.png",
        "/Projects/EssityGatepass/12.png",
        "/Projects/EssityGatepass/13.png",
        "/Projects/EssityGatepass/14.png",
        "/Projects/EssityGatepass/15.png",
        "/Projects/EssityGatepass/16.png",
        "/Projects/EssityGatepass/17.png",
      ],
      description: `Our admin panel provides a comprehensive solution for creating, approving, rejecting, or canceling various types of gate passes, as well as managing registers. With its intuitive and user-friendly interface, the admin panel allows administrators to efficiently manage multiple aspects of the gate pass process.
            Through the admin panel,
            administrators can quickly and easily create gate passes
            for different purposes,
            such as employee attendance,
            visitor management,
            or material movement.The panel also allows administrators to review and approve or reject gate pass requests submitted by employees or visitors,
            ensuring that only authorized individuals are granted access.
            In addition to gate pass management,
            the admin panel also offers functionality
            for managing registers,
            allowing administrators to easily view and update records related to gate passes,
            attendance,
            or other relevant data.With real - time updates and easy data retrieval,
            the admin panel offers a streamlined workflow
            for managing various types of records and information.
            Overall,
            our admin panel provides a powerful and efficient solution
            for managing gate passes and registers,
            helping organizations to enhance their security and streamline their operations.With its user - friendly interface and robust functionality,
            our admin panel is an essential tool
            for any organization seeking to optimize its gate pass management processes.
            `,
      stack_used: ["Laravel"],
      priority: 1,
      project_type: ["WebApp"],
      links: [],
      project_for: "work",
      date: "2022-12-01",
    },
    // {
    //   id: 20,
    //   title: "Essity Production management",
    //   company: "Essity",
    //   images: ["/Projects/EssityGatepass/main.jpg"],
    //   description: "",
    //   stack_used: ["Laravel"],
    //   priority: 0,
    //   project_type: ["WebApp"],
    //   links: [],
    //   project_for: "work",
    //   date: "2023-03-01",
    // },
    // {
    //   id: 21,
    //   title: "Car wash Management",
    //   company: "",
    //   images: [
    //     "/Projects/CarWashMgt/main.png",
    //     "/Projects/CarWashMgt/1.jpg",
    //     "/Projects/CarWashMgt/2.jpg",
    //     "/Projects/CarWashMgt/3.jpg",
    //     "/Projects/CarWashMgt/4.jpg",
    //   ],
    //   description: "",
    //   stack_used: ["React JS", "Express JS", "React Native"],
    //   priority: 0,
    //   project_type: ["WebApp", "MobileApp"],
    //   links: [],
    //   project_for: "personal",
    //   date: "2020-04-01",
    // },
    {
      id: 22,
      title: "LeaveMgt",
      company: "CodeRun",
      images: [
        "/Projects/LeaveMgt/main.jpg",
        "/Projects/LeaveMgt/1.jpg",
        "/Projects/LeaveMgt/2.jpg",
        "/Projects/LeaveMgt/3.jpg",
        "/Projects/LeaveMgt/4.jpg",
        "/Projects/LeaveMgt/5.jpg",
        "/Projects/LeaveMgt/6.jpg",
        "/Projects/LeaveMgt/7.jpg",
        "/Projects/LeaveMgt/8.jpg",
        "/Projects/LeaveMgt/9.jpg",
        "/Projects/LeaveMgt/10.jpg",
      ],
      description: `Our leave management app provides a user-friendly solution for managing employee leave requests and approvals. With its intuitive interface, employees can easily apply for leave and delete upcoming leaves that they had previously applied for. Employers can review and approve or reject leave requests, and update employees' pending leave count accordingly.
            To enhance the user experience and streamline the leave management process,
            our app also utilizes Firebase(FCM) to send notifications,
            ensuring that employees and employers are promptly notified of any updates or changes to leave requests.
            With its simple and efficient functionality,
            our app offers a powerful solution
            for managing employee leave,
            helping organizations to enhance their productivity and streamline their operations.The app can be easily accessed by clicking the link provided,
            providing a seamless experience
            for both employees and employers.
            `,
      stack_used: ["React Native", "Codeigniter V3", "MySQL"],
      priority: 0,
      project_type: ["MobileApp"],
      links: [
        {
          name: "LeaveMgt Playstore Link",
          link: "https://play.google.com/store/apps/details?id=com.CodeRun.LeaveMgt",
        },
      ],
      project_for: "work",
      date: "2020-05-01",
    },
    {
      id: 23,
      title: "PMAce",
      company: "CodeRun",
      images: [
        "/Projects/PMAce/main.jpg",
        "/Projects/PMAce/1.jpg",
        "/Projects/PMAce/2.jpg",
        "/Projects/PMAce/3.jpg",
        "/Projects/PMAce/4.jpg",
      ],
      description: `Our project management app provides a comprehensive solution for managing projects, tasks, and user performance. With its intuitive interface, administrators can create workspaces, add users to projects, and assign tasks with ease. The app also provides detailed reports on user performance that can be downloaded or sent via email, enabling administrators to make data-driven decisions and optimize productivity.
            Users can log time
            for each assigned task,
            allowing
            for accurate tracking of progress and resource allocation.With its efficient time - tracking system,
            our app provides a powerful tool
            for managing project timelines and ensuring timely delivery.
            To enhance the user experience and streamline communication,
            our app also utilizes Firebase(FCM) to send notifications,
            ensuring that team members are promptly notified of any updates or changes to assigned tasks or project deadlines.
            Overall,
            our project management app offers a powerful solution
            for managing projects and optimizing productivity.With its user - friendly interface,
            efficient time - tracking system,
            and comprehensive reporting functionality,
            our app provides a seamless and streamlined experience
            for administrators and users alike.
            `,
      stack_used: ["Java", "Django", "Postgresql"],
      priority: 0,
      project_type: ["MobileApp"],
      links: [
        {
          name: "PMAce Playstore Link",
          link: "https://play.google.com/store/apps/details?id=com.CodeRun.PMAce",
        },
      ],
      project_for: "work",
      date: "2020-02-01",
    },
    {
      id: 24,
      title: "OPWORTH",
      company: "CodeRun",
      images: [
        "/Projects/OPWORTH/main.jpg",
        "/Projects/OPWORTH/1.jpg",
        "/Projects/OPWORTH/2.jpg",
        "/Projects/OPWORTH/3.jpg",
      ],
      description: "",
      stack_used: ["Java", "Django", "Postgresql"],
      priority: 0,
      project_type: ["MobileApp"],
      links: [
        {
          name: "OPWORTH Playstore Link",
          link: "https://play.google.com/store/apps/details?id=com.coderun.opworth",
        },
      ],
      project_for: "work",
      date: "2020-03-11",
    },
    {
      id: 25,
      title: "Mechgiri",
      company: "Mechgiri",
      images: [
        "/Projects/Mechgiri/main.png",
        "/Projects/Mechgiri/1.png",
        "/Projects/Mechgiri/2.png",
        "/Projects/Mechgiri/3.png",
        "/Projects/Mechgiri/4.png",
        "/Projects/Mechgiri/5.png",
        "/Projects/Mechgiri/6.png",
        "/Projects/Mechgiri/7.png",
        "/Projects/Mechgiri/8.png",
        "/Projects/Mechgiri/9.png",
        "/Projects/Mechgiri/10.png",
        "/Projects/Mechgiri/11.png",
        "/Projects/Mechgiri/12.png",
        "/Projects/Mechgiri/13.png",
        "/Projects/Mechgiri/14.png",
        "/Projects/Mechgiri/15.png",
        "/Projects/Mechgiri/16.png",
        "/Projects/Mechgiri/17.png",
        "/Projects/Mechgiri/18.png",
        "/Projects/Mechgiri/19.png",
        "/Projects/Mechgiri/20.png",
        "/Projects/Mechgiri/21.png",
        "/Projects/Mechgiri/22.png",
        "/Projects/Mechgiri/23.png",
        "/Projects/Mechgiri/24.png",
        "/Projects/Mechgiri/25.png",
      ],
      description: `Our app provides a seamless solution for booking appointments and purchasing parts related to car services. Users can easily book appointments for various services, including car washes, and purchase parts from service centers registered with our website.
            To enhance the user experience,
            our app utilizes a user - friendly interface that allows
            for easy navigation and streamlined booking processes.Additionally,
            we offer a dashboard created using React JS
            for service centers,
            providing a powerful tool
            for managing their services and products.
            With its intuitive design and efficient functionality,
            our app provides a seamless experience
            for both users and service centers.The app can be easily accessed by clicking the link provided,
            ensuring a hassle - free experience
            for all users.
            `,
      stack_used: ["React Native", "React JS", "Express JS"],
      priority: 1,
      project_type: ["MobileApp", "WebApp"],
      links: [
        {
          name: "Mechgiri",
          link: "http://www.mechgiri.com",
        },
        {
          name: "Mechgiri Dashboard",
          link: "http://connect.mechgiri.com",
        },
        {
          name: "Mechgiri Playstore Link",
          link: "https://play.google.com/store/apps/details?id=com.mechgiri.autoservice",
        },
      ],
      project_for: "work",
      date: "2020-08-01",
    },
    {
      id: 26,
      title: "Mediquik",
      company: "Millionways",
      images: [
        "/Projects/Mediquik/main.png",
        "/Projects/Mediquik/Admin/1.png",
        "/Projects/Mediquik/Admin/2.png",
        "/Projects/Mediquik/Admin/3.png",
        "/Projects/Mediquik/Admin/4.png",
        "/Projects/Mediquik/Admin/5.png",
        "/Projects/Mediquik/Admin/6.png",
        "/Projects/Mediquik/Admin/7.png",
        "/Projects/Mediquik/Admin/8.png",
        "/Projects/Mediquik/Admin/9.png",
        "/Projects/Mediquik/Admin/10.png",
        "/Projects/Mediquik/Consumer/1.jpg",
        "/Projects/Mediquik/Consumer/2.jpg",
        "/Projects/Mediquik/Consumer/3.jpg",
        "/Projects/Mediquik/Consumer/4.jpg",
        "/Projects/Mediquik/Consumer/5.jpg",
        "/Projects/Mediquik/Consumer/6.jpg",
        "/Projects/Mediquik/Consumer/7.jpg",
        "/Projects/Mediquik/Consumer/8.jpg",
        "/Projects/Mediquik/Consumer/9.jpg",
        "/Projects/Mediquik/Consumer/10.jpg",
        "/Projects/Mediquik/Consumer/11.jpg",
        "/Projects/Mediquik/Consumer/12.jpg",
        "/Projects/Mediquik/Consumer/13.jpg",
        "/Projects/Mediquik/Consumer/14.jpg",
        "/Projects/Mediquik/Consumer/15.jpg",
        "/Projects/Mediquik/Consumer/16.jpg",
        "/Projects/Mediquik/Consumer/17.jpg",
        "/Projects/Mediquik/Consumer/18.jpg",
        "/Projects/Mediquik/Consumer/19.jpg",
        "/Projects/Mediquik/Consumer/20.jpg",
        "/Projects/Mediquik/Consumer/21.jpg",
        "/Projects/Mediquik/Consumer/22.jpg",
        "/Projects/Mediquik/Consumer/23.jpg",
        "/Projects/Mediquik/Consumer/24.jpg",
        "/Projects/Mediquik/Consumer/25.jpg",
        "/Projects/Mediquik/Delivery/1.jpg",
        "/Projects/Mediquik/Delivery/2.jpg",
        "/Projects/Mediquik/Delivery/3.jpg",
        "/Projects/Mediquik/Delivery/4.jpg",
        "/Projects/Mediquik/Delivery/5.png",
        "/Projects/Mediquik/Delivery/6.jpg",
        "/Projects/Mediquik/Delivery/7.jpg",
        "/Projects/Mediquik/Delivery/8.jpg",
        "/Projects/Mediquik/Delivery/9.png",
        "/Projects/Mediquik/Delivery/10.jpg",
        "/Projects/Mediquik/Delivery/11.jpg",
        "/Projects/Mediquik/Delivery/12.jpg",
        "/Projects/Mediquik/Pharmacy/1.jpg",
        "/Projects/Mediquik/Pharmacy/2.jpg",
        "/Projects/Mediquik/Pharmacy/3.jpg",
        "/Projects/Mediquik/Pharmacy/4.jpg",
        "/Projects/Mediquik/Pharmacy/5.jpg",
        "/Projects/Mediquik/Pharmacy/6.jpg",
        "/Projects/Mediquik/Pharmacy/7.jpg",
        "/Projects/Mediquik/Pharmacy/8.jpg",
      ],
      description:
        "This project is a comprehensive online pharmacy platform with three separate applications: a customer app, a delivery app, and a pharmacy app. The customer app allows users to purchase medicine online, manage their addresses, add items to their cart, create a wishlist, and view their prescriptions. The delivery app is used by delivery personnel to deliver products to customers, with a map providing a detailed route from the pharmacy to the customer's address. The delivery app also includes a dashboard to check monthly earnings and total orders delivered. The pharmacy app is used by pharmacy personnel to manage orders and view monthly profits and total orders. Additionally, the project includes an admin dashboard to check all orders by all pharmacies, manage user profiles, and manage payments to pharmacies and delivery personnel. The backend was developed using ExpressJS with MongoDB as the database, while React Native was used for the mobile applications and ReactJS for the admin dashboard.",
      stack_used: ["React Native", "React JS", "Express JS"],
      priority: 1,
      project_type: ["WebApp", "MobileApp"],
      links: [
        {
          name: "Mediquik Consumer Playstore Link",
          link: "https://play.google.com/store/apps/details?id=com.mediquik",
        },
        {
          name: "Mediquik Pharmacy Playstore Link",
          link: "https://play.google.com/store/apps/details?id=com.pharmacyadmin",
        },
        {
          name: "Mediquik Delivery Playstore Link",
          link: "https://play.google.com/store/apps/details?id=com.deliverymediquik",
        },
        {
          name: "Mediquik Admin Dashboard",
          link: "http://admin.millionways.co.in/",
        },
      ],
      project_for: "work",
      date: "2022-11-01",
    },
    {
      id: 27,
      title: "Zymmo",
      company: "Wafer",
      images: [
        "/Projects/Zymmo/main.png",
        "/Projects/Zymmo/1.jpg",
        "/Projects/Zymmo/2.jpg",
        "/Projects/Zymmo/3.jpg",
        "/Projects/Zymmo/4.jpg",
        "/Projects/Zymmo/5.jpg",
        "/Projects/Zymmo/6.jpg",
        "/Projects/Zymmo/7.jpg",
        "/Projects/Zymmo/8.jpg",
        "/Projects/Zymmo/9.jpg",
        "/Projects/Zymmo/10.jpg",
        "/Projects/Zymmo/11.jpg",
        "/Projects/Zymmo/12.jpg",
        "/Projects/Zymmo/13.jpg",
        "/Projects/Zymmo/14.jpg",
        "/Projects/Zymmo/15.jpg",
      ],
      description:
        "This app is designed to provide a platform for chefs and brands to showcase their products through short videos. Users can view the videos based on their location and have the option to visit the website of the chef or brand to purchase their products through Shopify. The app's backend is developed using Node.js and MongoDB, while the front end is built using React Native. The app also integrates Google Maps API to enable location-based content delivery. With this app, chefs and brands can create engaging videos to promote their products, and users can discover and purchase products from their favorite chefs and brands with ease.",
      stack_used: ["React Native"],
      priority: 0,
      project_type: ["MobileApp"],
      links: [
        {
          name: "Zymmo Playstore Link",
          link: "https://play.google.com/store/apps/details?id=com.zymmo_new&hl=en_IN&gl=US",
        },
      ],
      project_for: "work",
      date: "2022-03-01",
    },
    {
      id: 28,
      title: "Learnsure",
      company: "Wafer",
      images: [
        "/Projects/Learnsure/main.png",
        "/Projects/Learnsure/1.png",
        "/Projects/Learnsure/2.png",
        "/Projects/Learnsure/3.png",
        "/Projects/Learnsure/4.png",
        "/Projects/Learnsure/5.png",
        "/Projects/Learnsure/6.png",
        "/Projects/Learnsure/7.png",
        "/Projects/Learnsure/8.png",
        "/Projects/Learnsure/9.png",
        "/Projects/Learnsure/10.png",
        "/Projects/Learnsure/11.png",
        "/Projects/Learnsure/12.png",
        "/Projects/Learnsure/13.png",
        "/Projects/Learnsure/14.png",
        "/Projects/Learnsure/15.png",
        "/Projects/Learnsure/16.png",
        "/Projects/Learnsure/17.png",
        "/Projects/Learnsure/18.png",
        "/Projects/Learnsure/19.png",
        "/Projects/Learnsure/20.png",
        "/Projects/Learnsure/21.png",
        "/Projects/Learnsure/22.png",
        "/Projects/Learnsure/23.png",
        "/Projects/Learnsure/24.png",
        "/Projects/Learnsure/25.png",
        "/Projects/Learnsure/26.png",
        "/Projects/Learnsure/27.png",
        "/Projects/Learnsure/28.png",
        "/Projects/Learnsure/29.png",
        "/Projects/Learnsure/30.png",
        "/Projects/Learnsure/31.png",
        "/Projects/Learnsure/32.png",
        "/Projects/Learnsure/33.png",
        "/Projects/Learnsure/34.png",
        "/Projects/Learnsure/35.png",
        "/Projects/Learnsure/36.png",
        "/Projects/Learnsure/37.png",
        "/Projects/Learnsure/38.png",
        "/Projects/Learnsure/39.png",
      ],
      description:
        "This app is designed to help employers provide their employees with an opportunity to improve their skills through relevant courses. Employers can purchase courses related to their work, which include a mix of different content formats such as text, PDFs, videos, and more. These courses can be either free or paid. The app also provides a feature for employees to book appointments with course creators for one-on-one interaction. This way, employees can get personalized guidance and ask questions related to the course content. Overall, this app aims to enhance the skills of employees, which can help boost their productivity and ultimately benefit the company.",
      stack_used: ["React Native"],
      priority: 0,
      project_type: ["MobileApp"],
      links: [
        {
          name: "Learnsure Playstore Link",
          link: "https://play.google.com/store/apps/details?id=com.learnsure.ai&hl=en_IN&gl=US",
        },
      ],
      project_for: "work",
      date: "2022-01-01",
    },
    {
      id: 29,
      title: "LiveConfirm",
      company: "CodeMax",
      images: [
        "/Projects/LiveConfirm/main.jpg",
        "/Projects/LiveConfirm/1.jpg",
        "/Projects/LiveConfirm/2.jpg",
        "/Projects/LiveConfirm/3.jpg",
        "/Projects/LiveConfirm/4.jpg",
        "/Projects/LiveConfirm/5.jpg",
        "/Projects/LiveConfirm/6.jpg",
        "/Projects/LiveConfirm/7.jpg",
        "/Projects/LiveConfirm/8.jpg",
        "/Projects/LiveConfirm/9.jpg",
        "/Projects/LiveConfirm/10.jpg",
        "/Projects/LiveConfirm/11.jpg",
        "/Projects/LiveConfirm/12.jpg",
        "/Projects/LiveConfirm/13.jpg",
      ],
      description:
        "This app is based on the concepts of two-factor authentication (2FA) and has been designed to replace SMS-based OTPs for a product created by the company. It comes equipped with a dashboard that allows both admins and customers to view all requests and their statuses that were initiated using the app. With the aim of providing enhanced security measures, this app offers an added layer of protection to its users. By enabling the use of authentication factors like biometrics, it aims to ensure a more secure user experience. Its an intuitive interface and robust functionality make it an ideal choice for organizations that are looking to secure their sensitive data while providing a seamless user experience.",
      stack_used: ["React Native", "Laravel"],
      priority: 0,
      project_type: ["MobileApp"],
      links: [],
      project_for: "work",
      date: "2021-01-01",
    },
    // {
    //   id: 30,
    //   title: "BNI Dynamic",
    //   company: "Haztech",
    //   images: [
    //     "/Projects/BNIDynamic/main.png",
    //     "/Projects/BNIDynamic/1.jpg",
    //     "/Projects/BNIDynamic/2.jpg",
    //     "/Projects/BNIDynamic/3.jpg",
    //     "/Projects/BNIDynamic/4.jpg",
    //     "/Projects/BNIDynamic/5.jpg",
    //     "/Projects/BNIDynamic/6.jpg",
    //     "/Projects/BNIDynamic/7.jpg",
    //     "/Projects/BNIDynamic/8.jpg",
    //     "/Projects/BNIDynamic/9.jpg",
    //     "/Projects/BNIDynamic/10.jpg",
    //     "/Projects/BNIDynamic/11.jpg",
    //     "/Projects/BNIDynamic/12.jpg",
    //     "/Projects/BNIDynamic/13.jpg",
    //     "/Projects/BNIDynamic/14.jpg",
    //     "/Projects/BNIDynamic/15.jpg",
    //     "/Projects/BNIDynamic/16.jpg",
    //   ],
    //   description:
    //     "It’s an offline concept App that was designed for attendance management.",
    //   stack_used: ["React Native", "SQLite"],
    //   priority: 0,
    //   project_type: ["MobileApp"],
    //   links: [],
    //   project_for: "work",
    //   date: "2018-11-01",
    // },
    // {
    //   id: 31,
    //   title: "Docassist",
    //   company: "Apexuara",
    //   images: [],
    //   description:
    //     "The User can book appointments with a doctor, upload/view their reports, and view prescriptions.",
    //   stack_used: ["React Native", "Php", "MySQL"],
    //   priority: 0,
    //   project_type: ["MobileApp"],
    //   links: [],
    //   project_for: "work",
    //   date: "2019-11-01",
    // },
    // {
    //   id: 32,
    //   title: "Hrudyanad",
    //   company: "Apexuara",
    //   images: [],
    //   description:
    //     "Here the user can log in / Signup, update their profile which then can be used on the Hrudyanad website and also upload their blood reports so the admin can track their health progress.",
    //   stack_used: ["React Native", "Php", "MySQL"],
    //   priority: 0,
    //   project_type: ["MobileApp"],
    //   links: [],
    //   project_for: "work",
    //   date: "2019-09-22",
    // },
    {
      id: 33,
      title: "TripLover",
      company: "Bloombit",
      images: [
        "/Projects/TripLover/main.png",
        "/Projects/TripLover/1.jpg",
        "/Projects/TripLover/2.jpg",
        "/Projects/TripLover/3.jpg",
        "/Projects/TripLover/4.jpg",
        "/Projects/TripLover/5.jpg",
        "/Projects/TripLover/6.jpg",
        "/Projects/TripLover/7.jpg",
        "/Projects/TripLover/8.jpg",
        "/Projects/TripLover/9.jpg",
        "/Projects/TripLover/10.jpg",
        "/Projects/TripLover/11.jpg",
        "/Projects/TripLover/12.jpg",
        "/Projects/TripLover/13.jpg",
        "/Projects/TripLover/14.jpg",
        "/Projects/TripLover/15.jpg",
        "/Projects/TripLover/16.jpg",
        "/Projects/TripLover/17.jpg",
        "/Projects/TripLover/18.jpg",
        "/Projects/TripLover/19.jpg",
        "/Projects/TripLover/20.jpg",
        "/Projects/TripLover/21.jpg",
        "/Projects/TripLover/22.jpg",
        "/Projects/TripLover/23.jpg",
      ],
      description:
        "App that users can use to book flight tickets and hotel rooms",
      stack_used: ["React Native"],
      priority: 0,
      project_type: ["MobileApp"],
      links: [],
      project_for: "work",
      date: "2022-05-01",
    },

    // {
    //   id: 34,
    //   title: "Watch Store (College Mini Project)",
    //   company: "",
    //   images: ["", "/Projects/WatchStore/1.png", "/Projects/WatchStore/2.png"],
    //   description:
    //     "It’s an offline Watch store where users can buy a wristwatch just like they can from any online store. The UI was made using C# Forms and product information was saved in Microsoft Access Database.",
    //   stack_used: ["C#", "Microsoft Access"],
    //   priority: 0,
    //   project_type: ["DesktopApp"],
    //   links: [],
    //   project_for: "personal",
    //   date: "2015-05-01",
    // },
    // {
    //   id: 35,
    //   title: "Sports Management system",
    //   company: "",
    //   images: [],
    //   description:
    //     "It is an offline application that lets the user save data about a student i.e. what games that student plays, marks that the student got for playing those games, and gives attendance for that day and then save it in Access Database. It can be then converted to excel sheets or Word files in some cases and then can be printed.",
    //   stack_used: ["C#", "Microsoft Access"],
    //   priority: 0,
    //   project_type: ["DesktopApp"],
    //   links: [],
    //   project_for: "personal",
    //   date: "2018-06-01",
    // },
  ],
};

export default projects;
