import React from "react";
import "../styles/Footer.scss";
import React1 from "../assets/react.png";

export default function Footer() {
  return (
    <footer>
      <div className="waves">
        <div className="wave" id="wave1"></div>
        <div className="wave" id="wave2"></div>
        <div className="wave" id="wave3"></div>
        <div className="wave" id="wave4"></div>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <ul className="social_icons p-0">
          <li>
            <a href="https://github.com/ravinodesousa" target={"_blank"}>
              <i class="fa-brands fa-github"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/ravino-de-souza-225516143/"
              target={"_blank"}
            >
              <i class="fa-brands fa-linkedin"></i>
            </a>
          </li>
          {/* <li>
            <a href="https://www.instagram.com/ravino_souza/" target={"_blank"}>
              <i class="fa-brands fa-instagram"></i>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/ravino.dsouza/" target={"_blank"}>
              <i class="fa-brands fa-facebook"></i>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/Ravinodesouza" target={"_blank"}>
              <i class="fa-brands fa-twitter"></i>
            </a>
          </li> */}
        </ul>
        <div
          className="divider divider-white mt-2 mb-4"
          style={{ width: "75%" }}
        ></div>
      </div>
      <div className="footer-info">
        <span>
          Built with{" "}
          <a
            href="https://react.dev/"
            target={"_blank"}
            className="fw-bold text-white"
            style={{ textDecoration: "none" }}
          >
            <img src={React1} width="18px" /> React
          </a>
        </span>
      </div>
    </footer>
  );
}
