import React, { useEffect, useState } from "react";
import "../styles/AboutMe.scss";
import Avatar from "../assets/avatar.png";

export default function AboutMe() {
  const [exp, setExp] = useState(0);

  // useEffect(() => {
  //   first

  // }, [])

  return (
    <div className="about-me" id="about-me-section">
      <div className="container p-5 ">
        <div className="row">
          <div className="col-md-3 col-12 d-flex flex-column justify-content-center align-items-center">
            <img src={Avatar} alt="avatar" className="avatar" />
          </div>
          <div className="col-md-9 col-12 mt-3 d-flex flex-column">
            <div>
              <h2>About Me</h2>
              <div
                className="divider divider-white"
                style={{ width: 140 }}
              ></div>
              <p className="text-light mt-3 fst-italic">
                From my university days, I have been passionate about coding,
                and my interest only grew stronger when I learned C/C++.
                Throughout my career, I have accumulated over <b>5 years</b> of
                experience in full-stack software and app development.
                Presently, I work as a fullstack developer at <b>CodeRelix</b>,
                and I have also worked with teams for more than <b>4 years</b>{" "}
                at various workplaces. I am confident that my skills and
                expertise can add value to any organization. In my free time, I
                enjoy watching stand-up comedy videos, playing the latest
                single-player games, hitting the gym, and further improving my
                coding skills through online resources like YouTube and Udemy.
              </p>
            </div>
            <br />
            <div className="row">
              {/* <div className="col-12 col-md-8 d-flex flex-column">
                <h2>Contact Details</h2>
                <div
                  className="divider divider-white"
                  style={{ width: 220 }}
                ></div>
                <div className="mt-3">
                  <i
                    class="fa-solid fa-map-location-dot  me-2"
                    style={{ color: "#fff" }}
                  ></i>
                  <span>Margoa, Goa, India</span>
                </div>
                <div className="mt-3">
                  <i
                    class="fa-solid fa-phone  me-2"
                    style={{ color: "#fff" }}
                  ></i>
                  <span>
                    <a
                      className="text-decoration-none text-light"
                      href="tel:+919673630869"
                    >
                      9673630869
                    </a>{" "}
                    /{" "}
                    <a
                      className="text-decoration-none text-light"
                      href="tel:+918263019703"
                    >
                      8263019703
                    </a>
                  </span>
                </div>
                <div className="mt-3">
                  <i class="fa-solid fa-at  me-2" style={{ color: "#fff" }}></i>
                  <span>
                    <a
                      className="text-decoration-none text-light"
                      href="mailto:ravinodesouza@gmail.com"
                    >
                      ravinodesouza@gmail.com
                    </a>
                  </span>
                </div>
              </div> */}
              <div className="col-12 col-md-4 mt-3 d-flex flex-column justify-content-center align-items-start">
                <a
                  href="/ravino-de-souza-latest-resume-2024.pdf"
                  target={"_blank"}
                  className="resume-btn"
                >
                  <i class="fa-solid fa-download me-2"></i>Download Resume
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
