import React from "react";
import "../styles/Navbar.scss";
import { NavLink } from "react-router-dom";

export default function Navbar2() {
  return (
    <nav className="sticky navbar navbar-expand-lg navbar-light bg-dark">
      <div className="container">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <NavLink className="nav-link" to={"/?active=home"}>
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to={"/?active=about-me"}>
                About
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink className="nav-link" to={"/?active=my-work"}>
                Top Projects
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to={"/?active=resume"}>
                Skills
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to={"/?active=contact-me"}>
                Contact
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link active-link" to={"/all-projects"}>
                Projects
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
