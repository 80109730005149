import React, { useEffect, useState } from "react";
import Navbar2 from "../components/Navbar2";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { singleProject } from "../helpers/Project";
import Splash from "../components/Splash";

export default function ProjectDetail() {
  let { id } = useParams();
  const [project, setProject] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    setProject(singleProject(id));
  }, [id]);

  const formatImages = (images) => {
    return images
      .filter((item) => item != "")
      .map((item) => {
        return {
          original: item,
          thumbnail: item,
        };
      });
  };

  const formatLinks = (links) => {
    return links.map((item) => {
      return (
        <a className="text-black mx-2" href={item?.link} target="_blank">
          {item?.name}
        </a>
      );
    });
  };

  return (
    <>
      <Navbar2 />
      {/* detail start */}
      <div className="mt-5 pt-5" style={{ backgroundColor: "#FFF" }}>
        {project?.images?.length > 0 ? (
          <ImageGallery
            items={formatImages(project?.images)}
            //   thumbnailPosition="right"
            showBullets={true}
            showThumbnails={false}
            additionalClass="h-50 bg-secondary"
          />
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <img
              src={"/Projects/no-image.png"}
              style={{ width: 300, height: 300 }}
            />
          </div>
        )}
        <div className="p-5">
          <p className="project-detail-title">{project?.title}</p>

          {project?.company != "" && (
            <p className="project-company">Company: {project?.company}</p>
          )}
          <p className="project-stack-placeholder">
            Stack used:{" "}
            <span className="project-stack">
              {project?.stack_used?.join(", ")}
            </span>
          </p>

          {project?.links?.length > 0 && (
            <p className="project-links">
              Links: {formatLinks(project?.links)}
            </p>
          )}
          <p className="project-description">{project?.description}</p>
        </div>
      </div>
      {/* detail end */}
      <Footer />
    </>
  );
}
