import React, { useState } from "react";
import "../styles/ContactMe.scss";
import Programmer from "../assets/programmer.jpg";

export default function ContactMe() {
  const [name, setName] = useState({ value: "", error: "" });
  const [email, setEmail] = useState({ value: "", error: "" });
  const [message, setMessage] = useState({ value: "", error: "" });
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);

  const sendEmail = () => {
    let errors = false;
    if (name.value?.trim() == "") {
      errors = true;
      setName({ ...name, error: "Name required" });
    }

    if (email.value?.trim() == "") {
      errors = true;
      setEmail({ ...email, error: "Email required" });
    } else if (email.value?.trim() != "" && !validateEmail(email.value)) {
      errors = true;
      setEmail({ ...email, error: "Invalid required" });
      return;
    }

    if (message.value?.trim() == "") {
      errors = true;
      setMessage({ ...message, error: "Message required" });
    }

    if (!errors) {
      fetch("https://api.emailjs.com/api/v1.0/email/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          service_id: "service_dfwl3ab",
          template_id: "template_38hz7ir",
          user_id: "f9EPZjB1tKPWCPZuH",
          template_params: {
            from_name: name.value,
            email: email.value,
            message: message.value,
          },
        }),
      })
        .then((res) => res.text())
        .then((res) => {
          setShowSuccessMsg(true);
          setName({ value: "", error: "" });
          setEmail({ value: "", error: "" });
          setMessage({ value: "", error: "" });

          setTimeout(() => {
            setShowSuccessMsg(false);
          }, 3500);
        })
        .catch((err) => {
          console.log("Mail error: ", err);
          setShowErrorMsg(true);
          setTimeout(() => {
            setShowErrorMsg(false);
          }, 3500);
        });
    }
  };

  function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  return (
    <div
      className="contact-me "
      id="contact-me-section"
      style={{ backgroundColor: "#fff" }}
    >
      <div className="container p-5 ">
        <div className="row">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center">
            <h1>Get in touch</h1>
            <div
              className="divider divider-blue-non-animation mt-2 mb-4"
              style={{ width: 210 }}
            ></div>
          </div>
          <div className="col-12 my-3">
            <div className="row">
              <div className="col-12 col-md-4 my-2">
                <div className="card py-5 d-flex flex-column justify-content-center align-items-center contact-card">
                  <i
                    className="fa-solid fa-map-location-dot fa-3x mb-4"
                    style={{ color: "#fff" }}
                  ></i>
                  <h3 className="text-light">Address</h3>
                  <p className="mt-2 text-light">Margoa, Goa, India</p>
                </div>
              </div>
              <div className="col-12 col-md-4 my-2">
                <div className="card py-5 d-flex flex-column justify-content-center align-items-center contact-card">
                  <i
                    className="fa-solid fa-phone fa-3x mb-4"
                    style={{ color: "#fff" }}
                  ></i>
                  <h3 className="text-light">Contact No</h3>
                  <p className="mt-2 text-light">
                    <a
                      className="text-decoration-none text-light"
                      href="tel:+919673630869"
                    >
                      9673630869
                    </a>{" "}
                    /{" "}
                    <a
                      className="text-decoration-none text-light"
                      href="tel:+918263019703"
                    >
                      8263019703
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4 my-2">
                <div className="card py-5 d-flex flex-column justify-content-center align-items-center contact-card">
                  <i
                    className="fa-solid fa-at fa-3x mb-4"
                    style={{ color: "#fff" }}
                  ></i>
                  <h3 className="text-light">Email</h3>
                  <p className="mt-2 text-light">
                    <a
                      className="text-decoration-none text-light"
                      href="mailto:ravinodesouza@gmail.com"
                    >
                      ravinodesouza@gmail.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-12 d-flex flex-column justify-content-center align-items-center py-5">
            <img
              src={Programmer}
              alt="Contact me image"
              className="contact-me-img"
            />
          </div>
          <div className="col-md-5 col-12 d-flex flex-column justify-content-center align-items-center">
            {showSuccessMsg && (
              <div className="mb-3 w-100">
                <div class="alert alert-success" role="alert">
                  Successfully submitted data!
                </div>
              </div>
            )}

            {showErrorMsg && (
              <div className="mb-3 w-100">
                <div class="alert alert-danger" role="alert">
                  Failed to submit data!
                </div>
              </div>
            )}
            <div className="mb-3 w-100">
              <input
                className={`${
                  name.error != "" ? "is-invalid" : ""
                } form-control form-control-md`}
                type="text"
                placeholder="Name"
                onChange={(e) => setName({ value: e.target.value, error: "" })}
                value={name.value}
              />
              {name.error != "" && (
                <span className="text-danger">{name.error}</span>
              )}
            </div>
            <div className="mb-3 w-100">
              <input
                className={`${
                  email.error != "" ? "is-invalid" : ""
                } form-control form-control-md`}
                type="text"
                placeholder="Email"
                onChange={(e) => setEmail({ value: e.target.value, error: "" })}
                value={email.value}
              />
              {email.error != "" && (
                <span className="text-danger">{email.error}</span>
              )}
            </div>
            <div className="mb-3 w-100">
              <textarea
                className={`${
                  message.error != "" ? "is-invalid" : ""
                } form-control form-control-md`}
                type="text"
                placeholder="Message"
                rows="4"
                onChange={(e) =>
                  setMessage({ value: e.target.value, error: "" })
                }
                value={message.value}
              ></textarea>
              {message.error != "" && (
                <span className="text-danger">{message.error}</span>
              )}
            </div>

            <button
              type="button"
              className="btn btn-outline-primary btn-md"
              onClick={() => sendEmail()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
