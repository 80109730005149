import React, { useEffect, useState } from "react";
import "../styles/Navbar.scss";
import useSticky from "../helpers/useSticky";
import { NavLink } from "react-router-dom";

export default function Navbar({ activeSection }) {
  const { sticky, stickyRef } = useSticky();
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <nav
      ref={stickyRef}
      className={`navbar navbar-expand-lg navbar-dark ${
        windowSize[0] <= 991 ? "bg-custom-blue" : ""
      } ${sticky ? "sticky navbar-light bg-dark" : ""}`}
    >
      <div className="container">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <NavLink
                className={`nav-link ${
                  activeSection == "home" || activeSection == null
                    ? "active-link"
                    : ""
                }`}
                to={"?active=home"}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={`nav-link ${
                  activeSection == "about-me" ? "active-link" : ""
                }`}
                to={"?active=about-me"}
              >
                About
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                className={`nav-link ${
                  activeSection == "my-work" ? "active-link" : ""
                }`}
                to={"?active=my-work"}
              >
                Projects
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={`nav-link ${
                  activeSection == "resume" ? "active-link" : ""
                }`}
                to={"?active=resume"}
              >
                Skills
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={`nav-link ${
                  activeSection == "contact-me" ? "active-link" : ""
                }`}
                to={"?active=contact-me"}
              >
                Contact
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink className={`nav-link`} to={"/all-projects"}>
                All Projects
              </NavLink>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}
